import * as Sentry from "@sentry/react";
import React from "react";
import { createRoot } from "react-dom/client";
import { IrisRecommendType } from "./components/recommend-type";
import SignerService from "./services/SignerService";

Sentry.init({
  dsn: "https://ade7ea86fbe545f3b8071548c3aefac2@gt.iris.informa.com/3",
  environment: process.env.ENV,
});

const widgets = document.getElementsByClassName("iris-recommend");

Array.prototype.forEach.call(widgets, async function (widget) {
  const prop: any = {
    config: {},
  };
  [
    "division",
    "recipe",
    "variant",
    "mode",
    "itemUrl",
    "totalCards",
    "scrollPosition",
    "widgetType",
    "category",
    "author",
    "content_type",
    "defaultRuleEnabled",
    "itemType",
    "itemValue",

    // Common,
    "skin",
    "brand_family",
    "brand",
    "cardWidth",
    "title",
    "subTitle",
    "limit",
    "cards",
    "dateFormat",
    "redirectMode",
    "layout",
    "showEntityTag",
    "showDate",
    "showCardBgIcon",
    "entityImagePosition",
    "scrollInterval",
    "entityTagFont",
    "entityTagBackground",
    "scrollType",
    "imageIcon",
    "imageIconColor",
    "placement",
    "id",
    "mobilePreview",
    "uploadHtml",
    "htmlContent",

    // GEN 1
    "entity",
    "type",
    "by",
    "term",
    "period",
    "marketType",
    "site",
    "attribute",
    "carouselId",
    "showCompanyDetails",
    "showCardBgBlur",
    "showCompanyLogo",
    "scrollPosition",

    // ** BANNER ** //
    "widgetsize",
    "showBorder",
    "borderColor",
    "showBy",
    "showTime",
    "campaignId",
    "bannerId",
    "advertiserId",
    "targetUrl",
    "imageUrl",
    "rule",
    "issticky",
    "widgetposition",
    "userid",
    "media",
    "mediaPosition",
    "hascontent",
    "fontSize1",
    "fontSize2",
    "bannerTitle",
    "bannerDescription",
    "poster",
    "cookieChoice",

    // ** GRID ** //
    "rows",
    "columns",
    "borderRadius",
    "datetimebgcolor",
    "datetimefontcolor",
    "imagePosition",
    "dropShadow",
    "entitytagbordercolor",
    "datetimefontsize",
    "titleweight",
    "authorstitleweight",
    "imageWidth",
    "imageHeight",
    "maxAge",
    "showsponsored",
    "sponsoredtagbackground",
    "sponsoredtagfont",
    "sponsoredposition",
    "sponsoredcount",
    "additionalContentType",
    "additionalContentPosition",

    // ** POPUP ** //
    "actionLabel",
    "description",
    "popupTitle",
    "backgroundColor",
    "fontColor",
    "showActionLabel",
    "actionLabelBackground",
    "cookieName",
    "leftMargin",
    "bottomMargin",
    "rightMargin",
    "actionLabelFont",
    "animate",
    "timeDelay",
    "daysToShow",
    "buttonTopMargin",
    "buttonRightMargin",
    "showbuttonBorder",
    "borderSize",
    "buttonSize",
    "buttonColor",
    "buttonBorderColor",
    "buttonFill",
    "mobileViewImageUrl",
    "desktopwidgetsize",
    "mobilewidgetsize",
    "desktopscreen",
    "mobilescreen",
    "widgetwidth",
    "widgetheight",
    "utmparam",

    // ** PAGE ** //
    "pageUrl",
    "adminCenter",
    "configId",
    "endDate",
    "startDate",
    "env",
    "filterValue",
    "isEntitled",
    "mode",
    "experiments",
    "experimentState",
  ].forEach((id) => {
    prop.config[id] =
      typeof widget.getAttribute(id) !== "undefined" && widget.getAttribute(id)
        ? widget.getAttribute(id)
        : "";
  });

  if (prop.config.configId) {
    let apiUrl;
    if (prop.config.env) {
      apiUrl = `${process.env.ADMIN_API_BASE_URL_NONPROD}/public/config/item/${prop.config.configId}`;
    } else {
      prop.config.env = "prod";
      apiUrl = `${process.env.ADMIN_API_BASE_URL_PROD}/public/config/item/${prop.config.configId}`;
    }

    const response = await SignerService.signedAPIRequestV2(
      "execute-api",
      "GET",
      apiUrl,
      "",
      prop.config.env
    );

    const widgetConfig = {
      ...response.config.data_config.apiType.recommend,
      ...response.config.ui_config.widgettypes.banner,
      ...response.config.ui_config.widgettypes.carousel,
      ...response.config.ui_config.widgettypes.grid,
      ...response.config.ui_config.widgettypes.popup,
      widgetType: response.config.ui_config.defaultType,
      domain: response.brand.domain
        .replaceAll("/", "")
        .split(".")
        .slice(-2)
        .join("."),
    };
    delete widgetConfig["userid"];
    prop.config = { ...prop.config, ...widgetConfig };
  }

  const root = createRoot(widget);

  root.render((<IrisRecommendType {...prop} />) as React.ReactElement);
});

declare global {
  interface Window {
    IIRISTracker?: any;
  }
}
