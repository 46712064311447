import React from "react";
import ContentUnavailable from "./ContentUnavailable";
import { BannerWidget } from "./widgets/banner";
import { CarouselWidget } from "./widgets/carousel";
import { GridWidget } from "./widgets/grid";
import { PageRedirect } from "./widgets/page";
import { PopUpWidget } from "./widgets/popup";
import { StickyWidget } from "./widgets/sticky";
import { StickyPopUpWidget } from "./widgets/stickyPopup";

import "./iris-recommend.css";

const IrisRecommendType: React.FC<any> = (props) => {
  const config = props.config;
  //Reading Style configurations from properties
  const noOfcardItems = parseInt(config.cards);
  const cardMaxWidth = config.cardWidth || "210px";
  const apiDomain = process.env.API_BASE_URL;

  //Using approriate class based on the widget layout configuration
  let widgetLayout = config.layout.toLowerCase();
  let layoutStyling = "iiris-row";
  let cardStyling = "iiris-col-sm";

  const screenWidth = window.screen.width;
  const cardMaxWidthInt = cardMaxWidth.match(/\d+/)[0];
  if (screenWidth && cardMaxWidthInt) {
    if (
      screenWidth < cardMaxWidthInt * noOfcardItems &&
      widgetLayout == "horizontal"
    ) {
      widgetLayout = "vertical";
    }
  }

  if (widgetLayout == "vertical") {
    layoutStyling = "iiris-col";
    cardStyling = "iiris-row";
  } else if (widgetLayout == "horizontal") {
    layoutStyling = "iiris-row";
    cardStyling = "iiris-col-sm";
  }

  if (config.limit && +config.limit > 24) {
    return <ContentUnavailable />;
  }

  const WidgetLayout = () => {
    let layoutType: string | React.JSX.Element = "";
    switch (config.widgetType) {
      case "banner":
        layoutType = config.issticky ? (
          <StickyWidget config={config} apiDomain={apiDomain} />
        ) : (
          <BannerWidget config={config} apiDomain={apiDomain} />
        );
        break;
      case "grid":
        layoutType = <GridWidget config={config} apiDomain={apiDomain} />;
        break;
      case "sticky":
        layoutType = <StickyWidget config={config} apiDomain={apiDomain} />;
        break;
      case "carousel":
        layoutType = (
          <div id="iris-recommend-ui">
            <CarouselWidget
              config={config}
              noOfcardItems={noOfcardItems}
              cardMaxWidth={cardMaxWidth}
              widgetLayout={widgetLayout}
              layoutStyling={layoutStyling}
              cardStyling={cardStyling}
              apiDomain={apiDomain}
            />
          </div>
        );
        break;
      case "popup":
        layoutType = config.issticky ? (
          <StickyPopUpWidget config={config} apiDomain={apiDomain} />
        ) : (
          <PopUpWidget config={config} apiDomain={apiDomain} />
        );
        break;
      case "page":
        layoutType = <PageRedirect config={config} apiDomain={apiDomain} />;
        break;
      default:
        layoutType = (
          <div id="iris-recommend-ui">
            <CarouselWidget
              config={config}
              noOfcardItems={noOfcardItems}
              cardMaxWidth={cardMaxWidth}
              widgetLayout={widgetLayout}
              layoutStyling={layoutStyling}
              cardStyling={cardStyling}
              apiDomain={apiDomain}
            />
          </div>
        );
        break;
    }
    return layoutType;
  };
  
  return <WidgetLayout />;
};

export { IrisRecommendType };
