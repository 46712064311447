import { Signer } from "@aws-amplify/core";
import axios from "axios";

const SignerService = {
  signedAPIRequest: (service: any, method: any, url: any, body: any) => {
    const region = process.env.COGNITO_REGION;
    const identityId = process.env.COGNITO_IDENTITY_ID;
    const cognitoIdentityURL =
      "https://cognito-identity." + region + ".amazonaws.com/";
    const credsPayload = { IdentityId: identityId };
    const headers = {
      "Content-Type": "application/x-amz-json-1.1",
      "X-Amz-Target": "AWSCognitoIdentityService.GetCredentialsForIdentity",
    };

    const serviceResponse = axios
      .post(cognitoIdentityURL, credsPayload, { headers })
      .then(function (response) {
        const request = {
          method: method,
          url: url,
          data: body,
        };
        const access_info = {
          access_key: response.data.Credentials.AccessKeyId,
          secret_key: response.data.Credentials.SecretKey,
          session_token: response.data.Credentials.SessionToken,
        };
        const service_info = {
          service: service,
          region: region,
        };

        const signedRequest = Signer.sign(request, access_info, service_info);
        delete signedRequest.headers["host"];

        if (signedRequest.method !== "GET") {
          signedRequest.body = JSON.parse(signedRequest.body);
          signedRequest.data = JSON.parse(signedRequest.data);
        }

        const instance = axios.create();

        const result = instance(signedRequest)
          .then(function (apiresponse) {
            return apiresponse.data;
          })
          .catch(function (error) {
            console.log(error);
          });
        return result;
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function (signedresponse) {
        return signedresponse.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return serviceResponse;
  },

  signedAPIRequestV2: (
    service: any,
    method: any,
    url: any,
    body: any,
    env = "prod"
  ) => {
    const region = process.env.COGNITO_REGION;
    let identityId;
    if (env === "non-prod") {
      identityId = process.env.COGNITO_IDENTITY_ID_NONPROD;
    } else {
      identityId = process.env.COGNITO_IDENTITY_ID_PROD;
    }
    const cognitoIdentityURL =
      "https://cognito-identity." + region + ".amazonaws.com/";
    const credsPayload = { IdentityId: identityId };
    const headers = {
      "Content-Type": "application/x-amz-json-1.1",
      "X-Amz-Target": "AWSCognitoIdentityService.GetCredentialsForIdentity",
    };

    const serviceResponse = axios
      .post(cognitoIdentityURL, credsPayload, { headers })
      .then(function (response) {
        const request = {
          method: method,
          url: url,
          data: body,
        };
        const access_info = {
          access_key: response.data.Credentials.AccessKeyId,
          secret_key: response.data.Credentials.SecretKey,
          session_token: response.data.Credentials.SessionToken,
        };
        const service_info = {
          service: service,
          region: region,
        };

        const signedRequest = Signer.sign(request, access_info, service_info);
        delete signedRequest.headers["host"];

        const instance = axios.create();

        const result = instance(signedRequest)
          .then(function (apiresponse) {
            return apiresponse.data;
          })
          .catch(function (error) {
            console.log(error);
          });
        return result;
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function (signedresponse) {
        return signedresponse;
      })
      .catch(function (error) {
        console.log(error);
      });
    return serviceResponse;
  },

  // m2mSignedAPIRequest: (url: any, body: any) => {
  //   const cognitoPool = process.env.COGNITO_DOMAIN;
  //   const app_Id = process.env.APP_ID;
  //   const secret = process.env.APP_SECRET;
  //   if (!app_Id || !secret) {
  //     throw new Error("AppID or secret is undefined or empty string");
  //   }
  //   const headers = {
  //     "Content-Type": "application/x-www-form-urlencoded",
  //   };

  //   const params = new URLSearchParams();
  //   params.append("grant_type", "client_credentials");
  //   params.append("scope", "iris.apis/recommend");
  //   params.append("client_id", app_Id);
  //   params.append("client_secret", secret);
  //   const serviceResponse = axios
  //     .post(`https://${cognitoPool}/oauth2/token`, params, { headers })
  //     .then((authorization) => {
  //       const headers = {
  //         Authorization: `Bearer ${authorization.data.access_token}`,
  //       };
  //       const result = axios
  //         .post(url, body, { headers })
  //         .then((apiResponse) => apiResponse.data)
  //         .catch((error) => console.log(error));
  //       return result;
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     })
  //     .then(function (signedresponse) {
  //       return signedresponse;
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  //   return serviceResponse;
  // },
};

export default SignerService;
