import jsonLogic from "json-logic-js";
import React, { useEffect, useState } from "react";
import * as S from "../style";

import parser from "html-react-parser";
import SignerService from "../../services/SignerService";
import { decryptHTML } from "../../services/utils";
import { Data } from "../../types";
import "../iris-recommend.css";
import { defaultHtml } from "./constants";
import axios from "axios";

const CryptoJS = require("crypto-js");

let sendAdImp = true;

const BannerWidget: React.FC<any> = (props) => {
  const { config } = props;

  const primaryColor = config.skin;
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [targetUrl, setTargetUrl] = useState(config.targetUrl);
  const [poster, setPoster] = useState(config.poster);
  const [bannerTitle, setBannerTitle] = useState(config.bannerTitle);
  const [bannerDescription, setBannerDescription] = useState(
    config.bannerDescription
  );
  const [isShowErrorMsg, setIsShowErrorMsg] = useState<boolean>(false);
  const [isDesktopView, setIsDesktopView] = useState<boolean>(
    window.innerWidth > 500
  );
  const [htmlContent, setHtmlContent] = useState("");
  const [uploadHtml, setUploadHtml] = useState(false);

  useEffect(() => {
    if (config.entity === "campaign") {
      getSegments();
    }
  }, [isDesktopView]);

  useEffect(() => {
    function handleResize() {
      setIsDesktopView(
        window.innerWidth > 500 && !(config.adminCenter && config.mobilePreview)
      );
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  //Functions to check if any of the recommendation element is present in viewport
  const isInViewport = (elements: NodeListOf<HTMLElement>) => {
    let result = false;
    for (const element of elements) {
      result = checkIsInViewPort(element) || result;
      if (result) {
        break;
      }
    }
    return result;
  };

  const checkIsInViewPort = (elem: HTMLElement) => {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  //Tracking Ad Impressions
  const adImpression = (zone: string, redirectUrl?: string) => {
    if (window.IIRISTracker && window.IIRISTracker.trackImpression) {
      const impressionId = config.imageUrl || window.location.href;
      const bannerId = config.bannerId;
      const campaignId = config.campaignId;
      const advertiserId = config.advertiserId;

      let newImpressionId;
      try {
        newImpressionId = new URL(impressionId);
        newImpressionId.searchParams.append("widgetId", config.id);
        newImpressionId.searchParams.append(
          "rule",
          config.ruleNo ? config.ruleNo : 0
        );
        newImpressionId.searchParams.append("iiris-ref", "target");
      } catch {
        newImpressionId = impressionId.replaceAll(" ");
        newImpressionId += `?widgetId=${config.id}&rule=${
          config.ruleNo ? config.ruleNo : 0
        }&iiris-ref=target`;
      }

      const data: Data = {
        impressionId: newImpressionId.toString(),
        bannerId: bannerId,
        campaignId: campaignId,
        advertiserId: advertiserId,
        zoneId: zone,
      };

      if (zone === "click") {
        data.targetUrl = targetUrl || imageUrl;
        const ctrEvt = new CustomEvent("recommendCTREvent", {
          detail: { target_url: redirectUrl },
        });
        window.dispatchEvent(ctrEvt);
      } else if (zone === "viewport") {
        const viewportEvt = new CustomEvent("iiris_recommendViewportEvent", {
          detail: { impressionId: newImpressionId },
        });
        window.dispatchEvent(viewportEvt);
      }

      window.IIRISTracker.trackImpression(data);
    }
  };

  //Tracking Viewport event for ad impression
  window.addEventListener(
    "scroll",
    function (event) {
      if (sendAdImp) {
        if (
          isInViewport(
            this.document.querySelectorAll(".iiris-banner-main-container")
          )
        ) {
          // Ad Impression event when recommendation is seen by the user
          sendAdImp = false;
          adImpression("viewport");
        }
      }
    },
    false
  );

  const getRedirectMode = () => {
    const redirectionMode = config.redirectMode;
    let mode;
    if (redirectionMode === "new") {
      mode = "_blank";
    } else if (redirectionMode === "same") {
      mode = "_self";
    }
    return mode;
  };

  const addClickImpression = (sourceUrl: string) => {
    let redirectUrl;
    try {
      redirectUrl = new URL(sourceUrl);
      redirectUrl.searchParams.append("iiris-ref", "target");
    } catch (e) {
      redirectUrl = sourceUrl + "?iiris-ref=target";
    }
    // Ad Impression Event OnClick
    adImpression("click", redirectUrl.toString());
  };

  const checkCampaignValidity = () => {
    if (!config.adminCenter) {
      const now = new Date();
      const _endDate = new Date(config.endDate);
      const _startDate = new Date(config.startDate);
      const now_utc = Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        0
      );
      const endDate_utc = Date.UTC(
        _endDate.getFullYear(),
        _endDate.getMonth(),
        _endDate.getDate(),
        _endDate.getHours(),
        _endDate.getMinutes(),
        0
      );
      const startDate_utc = Date.UTC(
        _startDate.getFullYear(),
        _startDate.getMonth(),
        _startDate.getDate(),
        _startDate.getHours(),
        _startDate.getMinutes(),
        0
      );
      return config.endDate
        ? startDate_utc <= now_utc && now_utc <= endDate_utc
        : true;
    } else {
      return true;
    }
  };

  const getSegments = async () => {
    setIsLoading(true);
    const decryptKey = "secret_key_iiris_recommend";
    let rules: any[], token, image: string;
    if (config.adminCenter) {
      image = config.mobilePreview
        ? config.mobileViewImageUrl || config.imageUrl
        : config.imageUrl;
    } else {
      image = isDesktopView
        ? config.imageUrl
        : config.mobileViewImageUrl || config.imageUrl;
    }
    if (config.configId) {
      rules = config.rule;
      token = config.token;
    } else {
      const _ciphertext = CryptoJS.AES.decrypt(
        config.rule.toString(),
        decryptKey
      );
      const c_text = _ciphertext.toString(CryptoJS.enc.Utf8);
      const _rules = JSON.parse(c_text);
      rules = _rules.config;
      token = _rules.token;
    }

    if (!config.userid) {
      while (window.IIRISTracker?.getDUId() == undefined) {
        await new Promise((r) => setTimeout(r, 5)); //wait for tracker to load
      }
    }

    for (const rule of rules) {
      try {
        let segmentApiCall;
        if (JSON.stringify(rule).includes("batch")) {
          segmentApiCall = await axios.get(`${process.env.SEGMENT_API_BASE_URL}/public/treasuredata?token=${token}&first_party_tracking_id=${config.userid || window.IIRISTracker?.getDUId()}`)
        } else {
          segmentApiCall = await axios.get(`${process.env.SEGMENT_API_BASE_URL}/public/treasuredata?token=${token}&td_client_id=${config.userid || window.IIRISTracker?.getDUId()}`)
        }
        const segmentResponse = segmentApiCall.data
        if (segmentResponse && segmentResponse[0] && rules.length) {
          const valid = segmentResponse[0].values.some((value: any) => {
            let result: any;
            if (JSON.stringify(rule).includes("batch")) {
              result = {
                segmentId: [
                  {
                    batch: value,
                  },
                ],
              };
            } else {
              result = {
                segmentId: [
                  {
                    realTime: value,
                  },
                ],
              };
            }
            let output = rules.find((e) => jsonLogic.apply(e.tree, result));
            if (!output) {
              const result1 = { segmentId: value };
              output = rules.find((e) => jsonLogic.apply(e.tree, result1));
            }
            if (output && checkCampaignValidity()) {
              rules.forEach((rule, index) => {
                if (rule.id === output.id) {
                  config.ruleNo = index + 1;
                }
              });
              if (config.experiments) {
                let apiUrl;
                if (config.env && config.env !== "prod") {
                  apiUrl = `${
                    process.env.ADMIN_API_BASE_URL_NONPROD
                  }/public/target/statistics?banner_id=${
                    config.bannerId
                  }&campaign_id=${config.campaignId}&advertiser_id=${
                    config.advertiserId
                  }&page_urlhost=${
                    config.domain
                  }&start_date=${config.startDate.slice(0, -6)}`;
                } else {
                  config.env = "prod";
                  apiUrl = `${
                    process.env.ADMIN_API_BASE_URL_PROD
                  }/public/target/statistics?banner_id=${
                    config.bannerId
                  }&campaign_id=${config.campaignId}&advertiser_id=${
                    config.advertiserId
                  }&page_urlhost=${
                    config.domain
                  }&start_date=${config.startDate.slice(0, -6)}`;
                }
                SignerService.signedAPIRequestV2(
                  "execute-api",
                  "GET",
                  apiUrl,
                  "",
                  config.env
                ).then((response) => {
                  //get the total impressions count for targeted rule variants
                  const impressionsCount = response.reduce(
                    (acc: number, curr: any) => {
                      if (
                        curr.impression_id &&
                        curr.impression_id.split("?")[0] !== config.imageUrl
                      )
                        return acc + curr.view_count;
                      else return acc;
                    },
                    0
                  );
                  //get the next image to be shown
                  const imageUrl =
                    output.imageUrl[
                      impressionsCount % output.imageUrl.length
                    ];
                  if (config.experimentState === "Running") {
                    setImageUrl(
                      isDesktopView
                        ? imageUrl
                        : output.mobileViewImageUrl || imageUrl
                    );
                    config.imageUrl = imageUrl;
                  }
                });
              } else {
                let image;
                if (config.adminCenter) {
                  image = config.mobilePreview
                    ? output.mobileViewImageUrl || output.imageUrl
                    : output.imageUrl;
                } else {
                  image = isDesktopView
                    ? output.imageUrl
                    : output.mobileViewImageUrl || output.imageUrl;
                }
                setImageUrl(image);
                if (output.uploadhtml) {
                  config.uploadHtml = "yes";
                  setUploadHtml(true);
                  setHtmlContent(decryptHTML(output.htmlCode));
                }
                config.imageUrl = output.uploadhtml
                  ? output.htmlContent
                  : image;
              }
              setTargetUrl(output.targetUrl);
              setPoster(output.poster);
              setBannerTitle(output.bannerTitle);
              setBannerDescription(output.bannerDescription);
              setIsLoading(false);
              if (!config.imageUrl) {
                setIsShowErrorMsg(true);
              }
              return true;
            }
          })
          if (
            !valid &&
            config.defaultRuleEnabled &&
            checkCampaignValidity() &&
            (config.experiments
              ? config.experimentState === "Running"
              : true)
          ) {
            setImageUrl(image);
            setImageUrl(
              isDesktopView
                ? config.imageUrl
                : config.mobileViewImageUrl || config.imageUrl
            );
            if (config.uploadHtml) {
              setUploadHtml(true);
              setHtmlContent(decryptHTML(config.htmlContent));
              config.imageUrl = config.htmlContent;
            }
          }
        } else if (
          config.defaultRuleEnabled &&
          checkCampaignValidity() &&
          (config.experiments ? config.experimentState === "Running" : true)
        ) {
          setImageUrl(image);
          if (config.uploadHtml) {
            setUploadHtml(true);
            setHtmlContent(decryptHTML(config.htmlContent));
            config.imageUrl = config.htmlContent;
          }
        }
        if (!config.imageUrl) {
          setIsShowErrorMsg(true);
        }
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        if (
          config.defaultRuleEnabled &&
          checkCampaignValidity() &&
          (config.experiments ? config.experimentState === "Running" : true)
        ) {
          setImageUrl(image);
          if (config.uploadHtml) {
            setUploadHtml(true);
            setHtmlContent(decryptHTML(config.htmlContent));
            config.imageUrl = config.htmlContent;
          }
        }
        if (!config.imageUrl) {
          setIsShowErrorMsg(true);
        }
        setIsLoading(false);
      }
    }
    if (
      config.defaultRuleEnabled &&
      checkCampaignValidity() &&
      (config.experiments ? config.experimentState === "Running" : true)
    ) {
      setImageUrl(image);
      if (config.uploadHtml) {
        setUploadHtml(true);
        setHtmlContent(decryptHTML(config.htmlContent));
        config.imageUrl = config.htmlContent;
      }
    } else {
      setIsShowErrorMsg(true);
    }
    setIsLoading(false);
  };

  const BannerAd: React.FC = () => {
    const sizes = config.widgetsize.split(" x ");
    return !config.hascontent ? (
      <div
        className="iiris-banner-main-container"
        style={{
          overflow: "hidden",
          margin: "1px",
        }}
        onMouseEnter={() => {
          adImpression("onMouseEnter");
        }}
      >
        {uploadHtml ? (
          parser(htmlContent || defaultHtml)
        ) : (
          <div key="3" className="iiris-banner-imagecontainer">
            <img
              style={{
                maxWidth: `${sizes[0]}px`,
                width: "100%",
                height: "auto",
                objectFit: "cover",
                objectPosition: "top",
              }}
              src={imageUrl}
            />
          </div>
        )}
      </div>
    ) : (
      <S.BannerContainer
        className="iiris-banner-main-container"
        mediaPosition={config.mediaPosition}
        maxWidth={sizes[0]}
        background={config.backgroundColor}
        fontSize1={config.fontSize1}
        fontSize2={config.fontSize2}
        onMouseEnter={() => {
          adImpression("onMouseEnter");
        }}
      >
        <div
          key="3"
          className="iiris-banner-imagecontainer"
          style={{
            width: `${
              ["left", "right"].includes(config.mediaPosition) ? "50%" : "100%"
            }`,
            padding: `${
              ["left", "right"].includes(config.mediaPosition)
                ? "0 .5rem"
                : ".5rem 0"
            }`,
            background: config.backgroundColor,
          }}
        >
          {config.media === "image" ? (
            <img
              style={{
                width: "100%",
                height: `${
                  ["left", "right"].includes(config.mediaPosition)
                    ? "100%"
                    : "auto"
                }`,
                objectFit: "cover",
                objectPosition: "top",
                aspectRatio: `${
                  ["left", "right"].includes(config.mediaPosition)
                    ? "16/9"
                    : "initial"
                }`,
              }}
              src={imageUrl}
            />
          ) : (
            <video
              poster={poster}
              src={imageUrl}
              autoPlay
              muted
              loop
              style={{ width: "100%", aspectRatio: "16/9" }}
            ></video>
          )}
        </div>
        <div
          className="iiris-banner-contentcontainer"
          style={{
            color: config.fontColor,
            textAlign: `${
              ["left", "right"].includes(config.mediaPosition)
                ? "left"
                : "center"
            }`,
            padding: "0 1rem",
            background: config.backgroundColor,
            width: `${
              ["left", "right"].includes(config.mediaPosition) ? "50%" : "100%"
            }`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p className="content-heading">{bannerTitle}</p>
          <p className="content-description">{bannerDescription}</p>
        </div>
      </S.BannerContainer>
    );
  };

  return (
    <div className="iiris-container">
      <div className="iiris-row" style={{ position: "relative" }}>
        <div className="iiris-col-12">
          {imageUrl || htmlContent ? (
            <div className="container-fluid">
              <S.BannerAds className="iiris-banner-container">
                {config.uploadHtml ? (
                  <BannerAd />
                ) : (
                  <a
                    href={targetUrl}
                    target={getRedirectMode()}
                    onClick={() => addClickImpression(targetUrl)}
                    className="a-none"
                  >
                    <BannerAd />
                  </a>
                )}
              </S.BannerAds>
            </div>
          ) : isLoading ? (
            <S.SpinnerContainer>
              <S.Loader primaryColor={primaryColor}></S.Loader>
            </S.SpinnerContainer>
          ) : isShowErrorMsg && config.adminCenter === "yes" ? (
            <div className="flex align-items-center justify-content-center">
              <div>
                <h3 className="no-image flex justify-content-center">
                  No Banner
                </h3>
                <div>
                  <h6 className="flex justify-content-center">
                    User ID [{config.userid}] doesn’t match any targeting rule.
                  </h6>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { BannerWidget };
