import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import jsonLogic from "json-logic-js";
import React, { useEffect, useState } from "react";
import * as S from "../style";
import axios from "axios";

const PageRedirect: React.FC<any> = (props) => {
  const { config } = props;
  const [pageRedirectUrl, setPageRedirectUrl] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (config.entity === "campaign") {
      getSegments();
    }
  }, []);

  const redirectToDefault = () => {
    const impressionId = new URL(window.location.href);
    impressionId.searchParams.append("widgetId", config.id);
    impressionId.searchParams.append("rule", config.ruleNo ? config.ruleNo : 0);
    impressionId.searchParams.append("iiris-ref", "target");

    window.IIRISTracker.trackImpression({
      impressionId: impressionId.toString(),
      targetUrl: config.pageUrl,
      zoneId: "redirect",
    });
    setLoading(false);
    if (config.adminCenter) {
      setPageRedirectUrl(config.pageUrl);
    } else {
      window.location.replace(config.pageUrl);
    }
  };

  const checkCampaignValidity = () => {
    if (!config.adminCenter) {
      const now = new Date();
      const _endDate = new Date(config.endDate);
      const _startDate = new Date(config.startDate);
      const now_utc = Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        0
      );
      const endDate_utc = Date.UTC(
        _endDate.getFullYear(),
        _endDate.getMonth(),
        _endDate.getDate(),
        _endDate.getHours(),
        _endDate.getMinutes(),
        0
      );
      const startDate_utc = Date.UTC(
        _startDate.getFullYear(),
        _startDate.getMonth(),
        _startDate.getDate(),
        _startDate.getHours(),
        _startDate.getMinutes(),
        0
      );
      return config.endDate
        ? startDate_utc <= now_utc && now_utc <= endDate_utc
        : true;
    } else {
      return true;
    }
  };

  const getSegments = async () => {
    let rules: any[], token;
    if (config.configId) {
      rules = config.rule;
      token = config.token;
    } else {
      const _ciphertext = CryptoAES.decrypt(
        config.rule.toString(),
        "secret_key_iiris_recommend"
      );
      const c_text = _ciphertext.toString(CryptoENC);
      const _rules = JSON.parse(c_text);
      rules = _rules.config;
      token = _rules.token;
    }

    if (!config.userid) {
      while (window.IIRISTracker?.getDUId() == undefined) {
        await new Promise((r) => setTimeout(r, 5)); //wait for tracker to load
      }
    }

    for (const rule of rules) {
      try {
        let segmentApiCall;
        if (JSON.stringify(rule).includes("batch")) {
          segmentApiCall = await axios.get(`${process.env.SEGMENT_API_BASE_URL}/public/treasuredata?token=${token}&first_party_tracking_id=${config.userid || window.IIRISTracker?.getDUId()}`)
        } else {
          segmentApiCall = await axios.get(`${process.env.SEGMENT_API_BASE_URL}/public/treasuredata?token=${token}&td_client_id=${config.userid || window.IIRISTracker?.getDUId()}`)
        }
        const segmentResponse = segmentApiCall.data
        if (segmentResponse && segmentResponse[0] && rules.length) {
          const computeRules: boolean[] = [];
          let ruleConfig: any = null;
          segmentResponse[0].values.forEach((value: any) => {
            let result: any;
            if (JSON.stringify(rule).includes("batch")) {
              result = {
                segmentId: [
                  {
                    batch: value,
                  },
                ],
              };
            } else {
              result = {
                segmentId: [
                  {
                    realTime: value,
                  },
                ],
              };
            }
            let output = rules.find((e) => jsonLogic.apply(e.tree, result));
            if (!output) {
              const result1 = { segmentId: value };
              output = rules.find((e) => jsonLogic.apply(e.tree, result1));
            }
            if (output) {
              computeRules.push(true);
              if (ruleConfig === null) ruleConfig = output;
            } else if (!output && !JSON.stringify(rule).includes(value)) {
              computeRules.push(true);
            } else computeRules.push(false);
          });
          const valid = computeRules.every((item) => item);
          if (valid && ruleConfig !== null && checkCampaignValidity()) {
            setLoading(false);
            rules.forEach((rule, index) => {
              if (rule.id === ruleConfig.id) {
                config.ruleNo = index + 1;
              }
            });
            const impressionId = new URL(window.location.href);
            impressionId.searchParams.append("widgetId", config.id);
            impressionId.searchParams.append(
              "rule",
              config.ruleNo ? config.ruleNo : 0
            );

            window.IIRISTracker.trackImpression({
              impressionId: impressionId.toString(),
              targetUrl: ruleConfig.pageUrl,
              zoneId: "redirect",
            });
            if (config.adminCenter) {
              setPageRedirectUrl(ruleConfig.pageUrl);
            } else {
              window.location.replace(ruleConfig.pageUrl);
            }
            return true;
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (
      !pageRedirectUrl &&
      config.defaultRuleEnabled === "yes" &&
      checkCampaignValidity()
    ) {
      redirectToDefault();
    } else {
      setLoading(false);
    }
  };

  return config.adminCenter ? (
    pageRedirectUrl ? (
      <div style={{ width: "100%" }}>
        <p>
          {" "}
          If the link is not loaded please click on this{" "}
          <a href={pageRedirectUrl} target="blank">
            link
          </a>
        </p>
        <iframe
          src={pageRedirectUrl}
          title="Redirected Site"
          style={{ width: "100%", height: "350px" }}
        ></iframe>
      </div>
    ) : loading ? (
      <S.SpinnerContainer>
        <S.Loader primaryColor={"black"}></S.Loader>
      </S.SpinnerContainer>
    ) : config.adminCenter === "yes" ? (
      <div className="flex align-items-center justify-content-center">
        <div>
          <h3 className="no-image flex justify-content-center">No Page</h3>
          <div>
            <h6 className="flex justify-content-center">
              User ID [{config.userid}] doesn’t match any targeting rule.
            </h6>
          </div>
        </div>
      </div>
    ) : null
  ) : null;
};

export { PageRedirect };
