import { useState } from "react";

import { Icon } from "@iconify/react";
import parser from "html-react-parser";
import _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SignerService from "../../services/SignerService";
import { removeTags, showFormattedDate } from "../../services/utils";
import ContentUnavailable from "../ContentUnavailable";
import {
  getConvertedWatchTime,
  getParameterizedImageUrl,
  positionSponsored,
  underscoreWrapped,
} from "../helper";
import "../iris-recommend.css";
import * as S from "../style";
import GridProducts from "./gridProducts";

let sendAdImp = true;

const GridWidget: React.FC<any> = (props) => {
  const { config, apiDomain } = props;

  const primaryColor = config.skin;
  const [gridData, setGridData] = useState<any[]>([]);
  const [gridDataLoaded, setGridDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getGridAds = async () => {
    let url = "";
    if (config.recipe) {
      url = `${apiDomain}/v4/recommend/public/`;
      if (config.entity) {
        url += `${config.entity === "video" ? "content" : config.entity}/`;
      }
      if (config.recipe) {
        url += `${config.recipe}/`;
      }
      if (["popular-items", "recent-items"].includes(config.recipe)) {
        if (config.by) {
          url += `${config.by}/`;
        }
      } else if (config.variant) {
        url += `${config.variant}/`;
      }
      if (config.division) {
        url += `${config.division}/`;
      }
      if (config.brand_family) {
        url += `${config.brand_family}/`;
      }
      if (config.brand) {
        url += `${config.brand}`;
      }
    } else {
      url = `${apiDomain}/v2/recommend/public/${
        config.entity === "product" ? "products" : "content"
      }/${
        config.entity === "product" ? config.marketType : config.brand_family
      }`;
      if (config.entity === "product") {
        url = url + "/" + config.brand;
      } else if (config.entity === "content") {
        url = url + "/" + config.site;
      }
    }

    const recommendUrl = new URL(url);
    if (config.recipe) {
      if (["popular-items", "recent-items"].includes(config.recipe)) {
        if (config.category || config.author || config.content_type) {
          recommendUrl.searchParams.set(
            "value",
            encodeURIComponent(
              config.category || config.author || config.content_type
            )
          );
        }
      } else if (config.itemUrl && config.recipe !== "user") {
        let itemUrl = config.itemUrl.replace(/\s/g, "");
        itemUrl = itemUrl.split("?");
        recommendUrl.searchParams.set("item", itemUrl[0]);
      }
      recommendUrl.searchParams.set(
        "limit",
        `${+config.rows * +config.columns}`
      );
    } else {
      if (config.entity === "content") {
        recommendUrl.searchParams.set("attribute", "url");
      }
      if (config.entity === "product") {
        //hotfix - by category should be most-viewed
        //recommendUrl.searchParams.set("type", 'more-like');
        if (config.by != "") {
          recommendUrl.searchParams.set("by", config.by);
          if (config.by === "category") {
            recommendUrl.searchParams.set("type", "most-viewed");
          } else {
            recommendUrl.searchParams.set("type", "more-like");
          }
        }
      } else {
        if (config.type != "") {
          recommendUrl.searchParams.set("type", config.type);
        }
      }
      if (config.term != "") {
        const newTerm = config.term.replace(/\s/g, "");
        recommendUrl.searchParams.set("term", newTerm);
      }
      if (config.period != "") {
        recommendUrl.searchParams.set("period", config.period);
      }
      if (config.limit != "") {
        recommendUrl.searchParams.set("limit", config.limit);
      }
    }
    if (config.showsponsored === "yes") {
      recommendUrl.searchParams.set("num_sp_item", config.sponsoredcount);
    }
    recommendUrl.searchParams.set("mode", "db");
    config.maxAge !== ""
      ? recommendUrl.searchParams.set("item_age", config.maxAge)
      : recommendUrl.searchParams.set("item_age", "12");

    //Get Recommendations
    if (config.recipe !== "user") {
      if (config.additionalContentPosition) {
        const body = {
          mode: "db",
          item_url: config.itemUrl,
          limit: parseInt(config.rows) * parseInt(config.columns),
          num_sp_item:
            config.showsponsored === "yes" ? config.sponsoredcount : 0,
          content_type: config.additionalContentType,
          item_age: config.maxAge ? config.maxAge : 12,
          recipe_type: config.recipe,
        };
        SignerService.signedAPIRequest(
          "execute-api",
          "POST",
          `${apiDomain}/v4/recommend/public/content/recommend-items/${config.division}/${config.brand_family}/${config.brand}`,
          JSON.stringify(body)
        ).then((jsonData) => {
          setIsLoading(false);
          setGridDataLoaded(true);
          const data: any[] = _.orderBy(
            jsonData,
            "_source.site_published_time",
            "desc"
          );
          const formattedArticles = positionSponsored(
            data,
            config.sponsoredposition,
            config.showsponsored,
            config.additionalContentPosition,
            config.additionalContentType
          );
          setGridData(formattedArticles);
        });
      } else {
        SignerService.signedAPIRequest(
          "execute-api",
          "GET",
          recommendUrl.toString(),
          ""
        ).then((jsonData) => {
          setIsLoading(false);
          setGridDataLoaded(true);
          const data: any[] = _.orderBy(
            jsonData,
            "_source.site_published_time",
            "desc"
          );
          const formattedArticles = positionSponsored(
            data,
            config.sponsoredposition,
            config.showsponsored,
            config.additionalContentPosition,
            config.additionalContentType
          );
          setGridData(formattedArticles);
        });
      }
    } else {
      const body: any = {
        id: config.itemValue,
        mode: config.mode,
        limit: config.entity === "product" ? 25 : config.rows * config.columns,
        filter_value: config.filterValue
          ? config.filterValue.split(",").map((it?: string) => it?.trim())
          : [],
        is_entitled: config.isEntitled ? "true" : "false",
      };
      if (config.showsponsored === "yes") {
        body["num_of_sponsored_item"] = parseInt(config.sponsoredcount);
      }
      // SignerService.m2mSignedAPIRequest(
      //   `${apiDomain}/v4/recommend/${config.entity}/user/${config.division}/${config.brand_family}/${config.brand}`,
      //   body
      // )
      //   .then((jsonData) => {
      //     setIsLoading(false);
      //     setGridDataLoaded(true);
      //     const data = jsonData?.data[0]?.recommendations;
      //     if (data?.length) {
      //       const formattedArticles = positionSponsored(
      //         data,
      //         config.sponsoredposition,
      //         config.showsponsored,
      //         config.additionalContentPosition,
      //         config.additionalContentType
      //       );
      //       setGridData(formattedArticles);
      //     }
      //   })
      //   .catch((err) => {
      //     setIsLoading(false);
      //     console.log(err);
      //   });
    }
  };

  const adImpression = (zone: string, redirectUrl?: string) => {
    if (window.IIRISTracker && window.IIRISTracker.trackImpression) {
      if (zone === "click") {
        const ctrEvt = new CustomEvent("recommendCTREvent", {
          detail: { target_url: redirectUrl },
        });
        window.dispatchEvent(ctrEvt);
      }
      const impressionId =
        window.location.host +
        "-" +
        Date.now() +
        "&iiris-ref=recommend&recipe=" +
        config.recipe;
      const bannerId = "iiris" + Date.now();
      const campaignId = "iiris_recommend";
      const advertiserId = "iiris";
      const targetUrl = window.location.href;

      const data = {
        impressionId: impressionId,
        bannerId: bannerId,
        campaignId: campaignId,
        advertiserId: advertiserId,
        targetUrl: targetUrl,
        zoneId: zone,
      };

      if (zone === "viewport") {
        const viewportEvt = new CustomEvent("iiris_recommendViewportEvent", {
          detail: { impressionId: impressionId },
        });
        window.dispatchEvent(viewportEvt);
      }

      window.IIRISTracker.trackImpression(data);
    }
  };

  //Functions to check if any of the recommendation element is present in viewport
  const isInViewport = (elements: NodeListOf<HTMLElement>) => {
    let result = false;
    for (const element of elements) {
      result = checkIsInViewPort(element) || result;
      if (result) {
        break;
      }
    }
    return result;
  };

  const checkIsInViewPort = (elem: HTMLElement) => {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  //Tracking Viewport event for ad impression
  window.addEventListener(
    "scroll",
    function (event) {
      if (sendAdImp) {
        if (
          isInViewport(
            this.document.querySelectorAll(
              ".iiris-grid-ads-main-container .iiris-row"
            )
          )
        ) {
          // Ad Impression event when recommendation is seen by the user
          sendAdImp = false;
          adImpression("viewport");
        }
      }
    },
    false
  );

  function getRedirectMode() {
    const redirectionMode = config.redirectMode;
    let mode;
    if (redirectionMode === "new") {
      mode = "_blank";
    } else if (redirectionMode === "same") {
      mode = "_self";
    }
    return mode;
  }

  function addClickImpression(sourceUrl: string) {
    let redirectUrl;
    try {
      redirectUrl = new URL(sourceUrl);
      redirectUrl.searchParams.append("iiris-ref", "recommend");
      redirectUrl.searchParams.append("placement", config.placement);
      redirectUrl.searchParams.append("widgetId", config.id);
      redirectUrl.searchParams.append("recipe", config.recipe);
    } catch (e) {
      redirectUrl =
        sourceUrl +
        `?iiris-ref=recommend&placement=${config.placement}&widgetId=${config.id}&recipe=${config.recipe}`;
    }
    // Ad Impression Event OnClick
    adImpression("click", redirectUrl.toString());
  }

  useEffect(() => {
    if (gridData.length == 0 && !gridDataLoaded) {
      getGridAds();
    }
  }, [gridData, gridDataLoaded]);

  const HorizontalWidget = ({ col }: { col: any }) => (
    <div className="grid-col-card grid-horizontal">
      <div className="grid-image-container">
        {config.showEntityTag && gridData[col]._source.type ? (
          <div className="grid-col-tag">
            <Icon
              icon={`bxs:file`}
              style={{
                color: "#fff",
                fontSize: "16px",
              }}
            />
          </div>
        ) : null}
        <LazyLoadImage
          key={gridData[col]._source.image}
          src={getParameterizedImageUrl(
            gridData[col]._source.image,
            config.columns,
            config.imagePosition
          )}
          className="img-lazy"
        />
        {config.showsponsored &&
          gridData[col]._source.is_sponsored &&
          gridData[col]._source.is_sponsored === "true" && (
            <div
              className="sponsored-tag"
              style={{
                background: config.sponsoredtagbackground,
                color: config.sponsoredtagfont,
              }}
            >
              sponsored
            </div>
          )}
      </div>
      <div className="grid-horizontal-content">
        <div className="grid-horizontal-card">
          <a
            href={gridData[col]._source.url}
            target={getRedirectMode()}
            onClick={() => addClickImpression(gridData[col]._source.url)}
            className="a-none"
          >
            <div className="key_name">Health & Pharma</div>
          </a>
        </div>
        <div className="grid-container-header">
          <div className="key_name">
            {gridData[col]._source.name
              ? removeTags(gridData[col]._source.name)
              : parser(
                  removeTags(gridData[col]._source.title).replace(
                    underscoreWrapped,
                    "<em>$1</em>"
                  )
                )}
          </div>
        </div>
        {config.showBy && gridData[col]._source.author ? (
          <div className="grid-container-showby">
            by
            <b> {gridData[col]._source.author}</b>
          </div>
        ) : null}
        {config.showDate ? (
          <div className="grid-container-date">
            <span className="date-cont">
              {config.showDate
                ? showFormattedDate(
                    gridData[col].date,
                    gridData[col]._source.site_published_date,
                    config.dateFormat
                  )
                : null}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );

  const VerticalWidget = ({ col }: { col: any }) => (
    <div className="grid-col-card grid-vertical">
      {config.showEntityTag &&
      gridData[col]._source.site_tags &&
      gridData[col]._source.site_tags.length ? (
        <div className="grid-col-tag">{gridData[col]._source.site_tags[0]}</div>
      ) : null}
      <div className="grid-image-container">
        <LazyLoadImage
          key={gridData[col]._source.image}
          src={getParameterizedImageUrl(
            gridData[col]._source.image,
            config.columns,
            config.imagePosition
          )}
          className="img-lazy"
        />
      </div>
      {config.showsponsored &&
        gridData[col]._source.is_sponsored &&
        gridData[col]._source.is_sponsored === "true" && (
          <div
            className="sponsored-tag"
            style={{
              background: config.sponsoredtagbackground,
              color: config.sponsoredtagfont,
            }}
          >
            sponsored
          </div>
        )}
      <div className="grid-vertical-content">
        <div className="grid-container-header">
          {gridData[col]._source.name
            ? removeTags(gridData[col]._source.name)
            : parser(
                removeTags(gridData[col]._source.title).replace(
                  underscoreWrapped,
                  "<em>$1</em>"
                )
              )}
        </div>
        {config.showBy && gridData[col]._source.author ? (
          <div className="grid-container-showby">
            by
            <b> {gridData[col]._source.author}</b>
          </div>
        ) : null}
        {config.showDate || config.showTime ? (
          <div className="grid-container-date">
            <span className="date-cont">
              {config.showDate
                ? showFormattedDate(
                    gridData[col].date,
                    gridData[col]._source.site_published_date,
                    config.dateFormat
                  )
                : null}
            </span>
            {config.showTime && gridData[col]._source.site_published_time ? (
              <span className="grid-container-time">
                {moment(gridData[col]._source.site_published_time)
                  .startOf("hour")
                  .fromNow()}
              </span>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );

  const VerticalVideoWidget = ({ col }: { col: any }) => (
    <div className="grid-col-card grid-vertical grid-video-vertical">
      <div className="grid-image-container video-img-container">
        <S.ImageContainer>
          <LazyLoadImage
            key={gridData[col]._source.image}
            src={getParameterizedImageUrl(
              gridData[col]._source.image,
              config.columns,
              config.imagePosition
            )}
            className="img-lazy"
          />
          <S.PlayIcon className="play-button">
            <img
              src="https://static.iris.informa.com/widgets/assets/play.png"
              style={{ borderRadius: "50%", cursor: "pointer" }}
            />
          </S.PlayIcon>
          <S.VideoInfo className="video-insights">
            <span>
              {getConvertedWatchTime(gridData[col]._source.watch_time)}
            </span>
          </S.VideoInfo>
        </S.ImageContainer>
      </div>
      <div className="grid-vertical-content">
        <S.CardTitle
          key={gridData[col]._id}
          data-toggle="tooltip"
          data-placement="bottom"
          title={removeTags(gridData[col]._source.title)}
        >
          <span className="author">
            {gridData[col]._source.author?.slice(
              0,
              gridData[col]._source.author.length - 1
            )}
          </span>
          <span className="hover-underline">
            {removeTags(gridData[col]._source.title)}
          </span>
        </S.CardTitle>
        <S.TagsWrapper>
          {gridData[col]._source.site_tags.map((item: any) => (
            <span key={item} className="video-tag">
              {item}
            </span>
          ))}
        </S.TagsWrapper>
      </div>
    </div>
  );

  const GridAd = () => {
    let grid_content = 0;

    return (
      <div className="iiris-grid-ads-main-container">
        {config.entity !== "product" ? (
          Array(+config.rows)
            .fill("")
            .map((e, i) =>
              Array(+config.columns)
                .fill("")
                .map((e, i) => grid_content++)
            )
            .map((row, i) => (
              <div
                className={`iiris-row grid-row${
                  config.imagePosition === "horizontal" ? "-horizontal" : ""
                }`}
                key={`${i}`}
                onMouseEnter={() => {
                  adImpression("onMouseEnter");
                }}
              >
                {row.map((col, j) => (
                  <div key={`${i}-${j}`} className="iiris-col-sm grid-col">
                    {gridData[col] && (
                      <a
                        href={gridData[col]?._source?.url}
                        target={getRedirectMode()}
                        onClick={() =>
                          addClickImpression(gridData[col]._source.url)
                        }
                        className="a-none"
                      >
                        <div
                          className={`grid-col-container${
                            config.entity === "video"
                              ? " video-col-container"
                              : ""
                          }`}
                        >
                          {config.entity === "video" ? (
                            <VerticalVideoWidget col={col} />
                          ) : config.imagePosition === "horizontal" ? (
                            <HorizontalWidget col={col} />
                          ) : (
                            <VerticalWidget col={col} />
                          )}
                        </div>
                      </a>
                    )}
                  </div>
                ))}
              </div>
            ))
        ) : (
          <GridProducts gridData={gridData} />
        )}
      </div>
    );
  };

  return (
    <div className="iiris-container">
      <div className="iiris-row" style={{ position: "relative" }}>
        <div className="iiris-col-12">
          {isLoading && (
            <S.SpinnerContainer>
              <S.Loader primaryColor={primaryColor}></S.Loader>
            </S.SpinnerContainer>
          )}
          {!isLoading && (
            <div className="container-fluid">
              <S.GridAds
                entityBackground={config.entityTagBackground}
                entityFont={config.entityTagFont}
                border={config.showBorder}
                borderRadius={`${config.borderRadius || 0}px`}
                bordercolor={config.borderColor}
                datetimebgcolor={config.datetimebgcolor}
                datetimefontcolor={config.datetimefontcolor}
                className="iiris-grid-ads-container"
                dropShadow={config.dropShadow}
                entitytagbordercolor={config.entitytagbordercolor}
                datetimefontsize={config.datetimefontsize}
                columns={+config.columns}
                titleweight={config.titleweight}
                authorstitleweight={config.authorstitleweight}
                imageWidth={config.imageWidth || 144}
                imageHeight={config.imageHeight || 144}
              >
                {gridData.length ? (
                  <div
                    className={`iiris-main-container-scroll ${
                      config.imagePosition === "horizontal"
                        ? "horizontal-ad-content"
                        : ""
                    }`}
                  >
                    <div className="iiris-col-12">
                      <S.Title
                        primaryColor={primaryColor}
                        showMargin={config.imagePosition === "horizontal"}
                        className={
                          config.widgetLayout == "vertical" &&
                          config.scrollPosition === "center"
                            ? "iiris-mb-4"
                            : "iiris-mb-3"
                        }
                      >
                        {config.title}
                      </S.Title>
                    </div>
                    <GridAd />
                  </div>
                ) : (
                  <ContentUnavailable />
                )}
              </S.GridAds>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { GridWidget };
